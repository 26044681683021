<template>
  <div>
    <div class="home__title__bottom">
      <div class="home__title__logo"><img :src="img" alt="logo"></div>
    </div>
    <div class="container" v-if="this.client">
      <div class="home__settings">
        <h2>{{client.name}}</h2>
        <p>{{client.position}}</p>
        <div class="home__settings__contact"><strong>{{$root.langText.setting.contact}}</strong>
          <ul>
            <li v-if="client.phone">
              <a @click="popupOpen('ContactsSettings', client.phone, 'tel', 'phone')">
                <div class="icon icon-phone"></div>
                <span>
                  <span>{{client.phone}}</span>
                  <b>{{$root.langText.setting.tabChange}}</b>
                </span>
              </a>
            </li>
            <li v-if="client.phone2">
              <a @click="popupOpen('ContactsSettings', client.phone2, 'tel', 'phone2')">
                <div class="icon icon-phone"></div>
                <span>
                  <span>{{client.phone2}}</span>
                  <b>{{$root.langText.setting.tabChange}}</b>
                </span>
              </a>
            </li>
            <li v-if="client.email">
              <a @click="popupOpen('ContactsSettings', client.email, 'email', 'email')">
                <div class="icon icon-mail"></div>
                <span>
                  <span>{{client.email}}</span>
                  <b>{{$root.langText.setting.tabChange}}</b>
                </span>
              </a>
            </li>
            <li v-if="client.email2">
              <a @click="popupOpen('ContactsSettings', client.email2, 'email', 'email2')">
                <div class="icon icon-mail"></div>
                <span>
                  <span>{{client.email2}}</span>
                  <b>{{$root.langText.setting.tabChange}}</b>
                </span>
              </a>
            </li>
          </ul>
          <div class="pop-up__btn pop-up__btn--one" v-if="phone || email">
            <a class="btn btn-primary" @click="popupOpen('ContactsSettings', '', 'tel', )">{{$root.langText.btnAdd}}
              <div class="icon-plus"></div>
            </a>
          </div>
        </div>
        <div class="home__settings__password">
          <strong>{{$root.langText.setting.setting}}</strong>
          <a @click="popupOpen('Password')">
            <div class="icon icon-lock"></div>
            {{$root.langText.setting.password}}
          </a>
        </div>
        <div class="home__settings__theme">
          <button :class="theme ? '' : 'active'" @click.prevent="themeActive(false)">{{$root.langText.setting.themeWhite}}</button>
          <button :class="theme ? 'active' : ''" @click.prevent="themeActive(true)">{{$root.langText.setting.themeDark}}</button>
        </div>
        <div v-if="$root.app !== 'SoftProCrmAppRioTransTest' && $root.app !== 'SoftProCrmAppRioTrans'">
          <strong style="margin-top: 20px">{{$root.langText.setting.lang}}</strong>
          <div class="home__settings__theme">
            <button :class="lang == 'ru' ? 'active' : ''" @click.prevent="langActive('ru')">RU</button>
            <button :class="lang == 'uk' ? 'active' : ''" @click.prevent="langActive('uk')">UA</button>
          </div>
        </div>
      </div>
    </div>
    <Popup :popup="popup" :client="{phone, email, client}" @close="closePopup" v-if="client"/>
  </div>
</template>


<style lang="scss">
  @import '../assets/home.css';
</style>

<script>
import Popup from '@/components/app/Popup'
import lang from '@/lang/lang';

export default {
  name: 'Settings',
  data: () => ({
    popup: {fade: false, mod: 'Password', id: '', id_contact: '', info: '', type: '', bd: 'email'},
    globalTitle: [
      {title: 'Мій профіль', info: ''}
    ],
    client: '',
    img: 'https://crm-core.soft-pro.com/img/user-img.svg',
    phone: false,
    email: false,
    theme: false,
    lang: 'uk',
  }),


  async mounted(){
    //get current
    const res = await this.$store.dispatch('getCurrentUser');
    this.client = res.data.detail;
    if(this.client){
      this.img = this.client.img_url
      this.popup.id = this.client.user_id
      this.popup.id_contact = this.client.id
      //title
      this.globalTitle[0].title = this.$root.langText.setting.title
      this.globalTitle[0].info = this.client.login
      this.$root.title = this.globalTitle

      if(!this.client.email){
        this.email = true
      }
      if(!this.client.email2){
        this.email = true
      } 
      if(!this.client.phone){
        this.phone = true
      }
      if(!this.client.phone2){
        this.phone = true
      }
    }
    if(localStorage.getItem('theme') == 'dark'){
      this.theme = true
    }
    this.lang = localStorage.getItem('lang')
  },
  methods: {
    themeActive(boolean){
      this.theme = boolean
      if(boolean == true){
        localStorage.setItem('theme', 'dark')
        document.body.classList.add("dark")
      } else{
        localStorage.removeItem('theme')
        document.body.classList.remove("dark")
      }
    },
    langActive(langActive){
      this.lang = langActive
      localStorage.setItem('lang', langActive)
      var app = this
      // var setInt = setInterval(function(){
        app.langText = lang(localStorage.getItem('lang'));
        app.lang = localStorage.getItem('lang');
      // }, 1000);
      setTimeout(function(){
        app.globalTitle[0].title = app.$root.langText.setting.title
        app.$root.title = app.globalTitle
      }, 1000);
    },
    popupOpen(data, info, type, bd){
      if(data == 'Password'){
        this.popup.mod = data
      } else{
        this.popup.mod = data
        this.popup.info = info
        this.popup.type = type
        this.popup.bd = bd

      }
      this.popup.fade = !this.popup.fade
    },
    async closePopup(){
      const res = await this.$store.dispatch('getCurrentUser');
      this.client = res.data.detail;
      this.popup.fade = !this.popup.fade;
      if(!this.client.email){
        this.email = true
      } else if(!this.client.email2){
        this.email = true
      } else{
        this.email = false
      }
      if(!this.client.phone){
        this.phone = true
      } else if(!this.client.phone2){
        this.phone = true
      } else{
        this.phone = false
      }
    }
  },
  components: {
     Popup
  },
  metaInfo(){
    return {
      title: this.$title(this.$root.langText.setting.titleMeta)
    }
  },
}
</script>